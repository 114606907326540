import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/imgs/1auth-logo-withoutxt.png';


const Footer = () => {
    return (
        <footer className="bg-black text-white py-10" data-aos="fade-down">
            <div className="max-w-7xl mx-auto px-6 lg:px-12">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <div className="mb-8 lg:mb-0">
                        <div className="flex items-center">
                            <img src={logo} alt="Logo" className="h-12 mr-4" />
                            <div>
                                <h1 className="text-xl font-bold">1Auth</h1>
                                <p className="text-sm text-gray-400">A Blitzwork Product</p>
                            </div>
                        </div>
                        <p className="mt-4 text-gray-400 text-lg font-semibold">An Enterprise <br /> Identity & Access Management
                            Solution</p>
                    </div>

                    <div className="grid grid-cols-2 gap-8 text-sm lg:gap-16">

                        <div>
                            <h3 className="font-semibold text-white mb-4">Products</h3>
                            <ul className="space-y-3">
                                {/* <li><Link to="#" className="text-gray-400 hover:text-white space-y-1">Overview</Link></li> */}
                                <li><Link to="ssoidp" className="text-gray-400 hover:text-white space-y-1">SSO & IDP</Link>
                                </li>
                                <li><Link to="amfa" className="text-gray-400 hover:text-white ">AMFA</Link></li>
                                <li><Link to="passwordmanager" className="text-gray-400 hover:text-white">Password
                                    Manager</Link></li>
                                <li><Link to="accessmanager" className="text-gray-400 hover:text-white">Access
                                    Manager</Link></li>
                                <li><Link to="clouddirectory" className="text-gray-400 hover:text-white">Cloud
                                    Directory</Link></li>
                                <li><Link to="userlifecycle" className="text-gray-400 hover:text-white">User Lifecycle
                                    Manager</Link></li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="font-semibold text-white mb-4">Verticals</h3>
                            <ul className="space-y-3">
                                <li><Link to="higheredu" className="text-gray-400 hover:text-white">Higher Education</Link></li>
                                <li><Link to="itesbpo" className="text-gray-400 hover:text-white">ITes & BPO</Link></li>
                                <li><Link to="manufacture" className="text-gray-400 hover:text-white">Manufacturing</Link>
                                </li>
                                <li><Link to="retail" className="text-gray-400 hover:text-white">Retail</Link></li>
                                <li><Link to="finserv" className="text-gray-400 hover:text-white">FinServ</Link></li>
                                <li><Link to="technology" className="text-gray-400 hover:text-white">Technology</Link></li>
                                <li><Link to="healthcare" className="text-gray-400 hover:text-white">Healthcare</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div
                    className="mt-8 border-t border-gray-700 pt-6 flex flex-col lg:flex-row justify-between items-center text-gray-400">
                    <p>All Rights Reserved 1Auth 2024</p>
                    <div className="mt-4 lg:mt-0 flex space-x-6">
                        <ul className="flex items-center max-lg:justify-center social-link gap-4">
                            <li>
                                <a href="#" className="transiton-all">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M26.25 14.0749C26.25 7.26802 20.7655 1.75 14 1.75C7.23451 1.75 1.75 7.26802 1.75 14.0749C1.75 20.2265 6.22962 25.3254 12.0859 26.25V17.6375H8.97559V14.0749H12.0859V11.3595C12.0859 8.27062 13.9148 6.5644 16.7129 6.5644C18.0528 6.5644 19.4551 6.80512 19.4551 6.80512V9.83819H17.9104C16.3888 9.83819 15.9141 10.7883 15.9141 11.764V14.0749H19.3115L18.7684 17.6375H15.9141V26.25C21.7704 25.3254 26.25 20.2265 26.25 14.0749Z"
                                            fill="white"
                                            className="fill-paragraph dark:fill-gray-100 hover:fill-primary dark:hover:fill-primary">
                                        </path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M14 1.75C7.23187 1.75 1.75 7.23187 1.75 14C1.75 19.4206 5.25656 23.9991 10.1259 25.6222C10.7384 25.7294 10.9681 25.3619 10.9681 25.0403C10.9681 24.7494 10.9528 23.7847 10.9528 22.7587C7.875 23.3253 7.07875 22.0084 6.83375 21.3194C6.69594 20.9672 6.09875 19.88 5.57812 19.5891C5.14937 19.3594 4.53688 18.7928 5.56281 18.7775C6.5275 18.7622 7.21656 19.6656 7.44625 20.0331C8.54875 21.8859 10.3097 21.3653 11.0141 21.0437C11.1213 20.2475 11.4428 19.7116 11.795 19.4053C9.06938 19.0991 6.22125 18.0425 6.22125 13.3569C6.22125 12.0247 6.69594 10.9222 7.47687 10.0647C7.35437 9.75844 6.92562 8.50281 7.59937 6.81844C7.59937 6.81844 8.62531 6.49687 10.9681 8.07406C11.9481 7.79844 12.9894 7.66062 14.0306 7.66062C15.0719 7.66062 16.1131 7.79844 17.0931 8.07406C19.4359 6.48156 20.4619 6.81844 20.4619 6.81844C21.1356 8.50281 20.7069 9.75844 20.5844 10.0647C21.3653 10.9222 21.84 12.0094 21.84 13.3569C21.84 18.0578 18.9766 19.0991 16.2509 19.4053C16.695 19.7881 17.0778 20.5231 17.0778 21.6716C17.0778 23.31 17.0625 24.6269 17.0625 25.0403C17.0625 25.3619 17.2922 25.7447 17.9047 25.6222C20.3365 24.8012 22.4497 23.2383 23.9468 21.1534C25.4438 19.0685 26.2493 16.5667 26.25 14C26.25 7.23187 20.7681 1.75 14 1.75Z"
                                            fill="white"></path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                        fill="none">
                                        <path
                                            d="M14.0037 2C9.04517 2 4.57804 4.9866 2.6827 9.56221C0.787361 14.1378 1.83395 19.409 5.33745 22.9125C8.84096 26.4161 14.1122 27.4626 18.6878 25.5673C23.2698 23.6783 26.2564 19.2112 26.2564 14.2527C26.2564 7.48819 20.7682 2 14.0037 2ZM10.4427 20.7045H7.76885V12.0893H10.4427V20.7045ZM9.1026 10.9151C8.47082 10.9151 7.90286 10.5386 7.66036 9.95787C7.41786 9.37715 7.54549 8.70708 7.9922 8.26036C8.43253 7.81365 9.1026 7.67964 9.68333 7.91576C10.2641 8.15188 10.647 8.71984 10.6533 9.34524C10.6533 10.2131 9.96412 10.9087 9.1026 10.9151ZM20.4555 20.7045H17.7816V16.5118C17.7816 15.5099 17.7625 14.2336 16.3904 14.2336C15.0184 14.2336 14.7759 15.3184 14.7759 16.4416V20.7045H12.1147V12.0893H14.6801V13.2635H14.7184C15.0758 12.5871 15.9437 11.8724 17.2455 11.8724C19.9513 11.8724 20.4491 13.6528 20.4491 15.9694V20.7045H20.4555Z"
                                            fill="white"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </footer>

    )

}
export default Footer;
