import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const Finserv = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Financial Services
                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Merging Data Security with Compliance

                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            The financial services industry manages vast amounts of sensitive data, operates under stringent regulatory requirements, and faces relentless threats from malicious actors. Data breaches not only cause severe financial losses but also erode customer trust. Implementing a carefully designed Identity and Access Management (IAM) strategy is essential to overcoming these challenges and safeguarding your organization.


                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Enhanced Identity-Centric Security


                        </h2>
                        <p className="text-gray-600 text-lg">
                            Over one-third of breaches in the financial industry result from insider actions, whether intentional or accidental, according to Verizon. Additionally, brute force and credential stuffing attacks—targeting the identity layer—account for a significant portion of incidents.
                            <br></br>
                            1Auth addresses these vulnerabilities with robust, multi-level identity and access security. Its blockchain-powered user directory ensures tamper-proof credentials, bolstered by multi-factor and passwordless authentication. Access can be restricted to internal networks for back-end roles or limited to whitelisted devices for remote or field employees, providing a comprehensive security framework.

                            <br></br>
                            Mitigate the risks of compromised credentials and identity-related breaches with 1Auth’s Cloud Directory, AMFA, and Access Manager.


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/fina.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/fina2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Data Security Compliance
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Financial institutions must adhere to stringent regulations such as GDPR in Europe, Sarbanes-Oxley in the USA, and PCI DSS globally. These frameworks mandate secure management, controlled access, and demonstrable protection of sensitive data, often requiring detailed audits for verification.

                            <br></br>

                            1Auth simplifies compliance for financial services by providing granular control over user access permissions, with comprehensive visibility through its Smart Analytics dashboard. Data is safeguarded with multi-factor authentication, access restrictions, and enforceable password policies, all of which are easily verifiable. Additionally, 1Auth maintains detailed audit logs of all user access activities, serving as a vital resource during compliance audits.

                            <br></br>

                            Ensure smoother compliance processes and provide verifiable data during audits with 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.



                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Empowering Productive Remote Operations

                        </h2>
                        <p className="text-gray-600 text-lg">
                            Employee productivity—whether in the field, at the office, or working from home—directly influences customer experience (CX). Conversely, delayed or inadequate access disrupts CX, while excessive access introduces security vulnerabilities.

                            <br></br>
                            1Auth ensures users receive precisely the permissions they need, streamlining application access through its single sign-on feature. To minimize productivity loss from password recovery, a self-service process is provided, granting immediate access when needed.

                            <br></br>

                            Equip your team with the tools necessary to maintain seamless operations and deliver exceptional CX using 1Auth’s Access Manager, SSO & IdP, and Password Manager.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/fina3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


        </>
    )

}



export default Finserv;