import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const NGO = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            NGOs


                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Secure and Cost-Effective Solutions to Empower Your Mission


                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            For non-profits, NGOs, and charitable trusts, maximizing every dollar while ensuring robust data security and privacy is a critical challenge. Striking this balance is essential, and an identity and access management (IAM) platform often plays a vital role in achieving it.





                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Data Security for Reinforced Trust


                        </h2>
                        <p className="text-gray-600 text-lg">
                            Handling sensitive data such as donor details, beneficiary information, and financial records demands stringent security measures. A data breach can disrupt operations and damage trust with current and potential donors.

                            <br /><br />

                            1Auth ensures robust data protection by implementing a zero-trust security strategy, granting users only the minimal permissions necessary for their roles. This approach is fortified with multi-factor authentication for secure logins and access restrictions based on whitelisted IP addresses and devices, preventing misuse.

                            <br /><br />

                            Safeguard your data with powerful, cost-effective security solutions using 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.

                            <br /><br />
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/profit.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/profit1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Frugal Efficiency to Empower Your Mission
                        </h2>
                        <p className="text-gray-600 text-lg">
                            A productive team and streamlined operations are crucial for advancing your mission. Reducing time-consuming processes and eliminating inefficiencies are key steps toward achieving this goal.


                            <br /><br />

                            1Auth automates access provisioning during onboarding, permission updates for role changes, and deprovisioning at exit, all based on configurable rules and roles. This significantly reduces the workload for IT administrators, enabling leaner teams. Single sign-on simplifies access for users, providing one-click entry to applications without juggling multiple credentials. Additionally, self-service password management minimizes IT team involvement in password recovery, delivering further time and cost savings.


                            <br /><br />
                            Optimize operational efficiency and maximize resources with 1Auth’s User Lifecycle Manager, SSO & IdP, and Password Manager.


                            <br /><br />

                        </p>
                    </div>
                </div>
            </section>



        </>
    )

}



export default NGO;