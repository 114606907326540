import React from 'react';
import { Link } from 'react-router-dom';

const FreeTrailBtn = () => {
    return (

        <>
            <Link to="/contact"
                className="px-10 py-5 bg-[#7829e3] text-white font-medium rounded-lg hover:bg-[#7829e3] transition-colors text-xl">
                Start your Free Trial
            </Link>
        </>
    )
}

export default FreeTrailBtn;