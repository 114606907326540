import React, { useState, useEffect } from "react";
import homeBannerImg from '../../assets/illustration/banner1.png';
import eduImg from '../../assets/illustration/16358535.png';
import retailerImg from '../../assets/illustration/19199574_nobac.png';
import dataPrivacyImg from '../../assets/illustration/5050677.png';
import bgImage from '../../assets/illustration/backLine.png';
import "./Carousel.scss";
import { Link } from 'react-router-dom';

const Carousel = () => {
    const slides = [
        {
            id: 1,
            image: homeBannerImg,
            heading: `<span class="text-white italic inline-block border border-white rounded px-2 py-1 mt-2 ">1Auth</span> <br /> One App for All Your Authentication Access`,
            paragraph: `Modernize access to all your digital tools with 1Auth, a reliable All-in-One Authentication app <span class="text-white text-sm italic font-bold inline-block border border-gray-700 rounded px-1 py-0.5 mt-1">1Auth</span>. It simplifies workflows, improves user experience, and strengthens security—all with effortless setup.`
        },
        {
            id: 2,
            image: eduImg,
            heading: `Academic Access Platform`,
            paragraph: `Simplify and secure access for students, faculty, and staff with an efficient and streamlined login management system.`
        },
        {
            id: 3,
            image: retailerImg,
            heading: `Online Retail Secuity Platform`,
            paragraph: `Provide seamless and secure customer authentication tailored for online retailers, enhancing user experience and safeguarding data.`
        },
        {
            id: 4,
            image: dataPrivacyImg,
            heading: `Data Privacy`,
            paragraph: `Data privacy in hybrid environments relies on encryption, access controls, and compliance with regulations like GDPR. Zero-trust security and privacy-enhancing technologies ensure secure data handling across systems.`
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    // Auto-slide every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            handleSlideChange((currentIndex + 1) % slides.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [currentIndex, slides.length]);

    const handleSlideChange = (index) => {
        if (animating) return;
        setAnimating(true);
        setTimeout(() => {
            setCurrentIndex(index);
            setAnimating(false);
        }, 500); // Match this duration with CSS animation
    };

    return (


        <div className="relative w-full max-w-[90vw] mx-auto h-auto flex flex-col md:flex-row items-center rounded-lg shadow-lg overflow-hidden bg-cover bg-center main-image-background main-banner2 sm:pt-10 md:pt-2">
            {/* Image Section */}
            <div
                className="flex w-full h-[82vh] transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {slides.map((slide, index) => (

                    <div
                        key={slide.id}
                        className="w-full flex flex-col md:flex-row items-center justify-center"
                        style={{ flex: "0 0 100%" }}
                    >
                        {/* Text Section */}
                        <div className="w-full md:w-1/2 px-6 py-8 flex flex-col justify-center">
                            <h1
                                className="sm:text-3xl md:text-4xl lg:text-5xl font-bold banner-header text-white"
                                dangerouslySetInnerHTML={{ __html: slide.heading }}
                            />
                            <p
                                className="sm:text-sm md:text-lg text-white mt-6 mb-10"
                                dangerouslySetInnerHTML={{ __html: slide.paragraph }}
                            />
                            <div className="flex justify-center md:justify-start">
                                <Link
                                    to="/contact"
                                    className="sm:px-3 sm:py-2 sm:text-sm md:px-5 md:py-3 bg-[#7829e3] text-white font-medium rounded-lg hover:bg-[#7829e3] transition-colors text-xl"
                                >
                                    Start your Free Trial
                                </Link>
                            </div>
                        </div>

                        {/* Image Section */}
                        <div className="w-full md:w-1/2 py-6 relative flex justify-center">
                            <img
                                src={slide.image}
                                alt={`Slide ${index + 1}`}
                                className="max-h-[25vh] md:max-h-[80vh] max-w-full object-contain"
                            />
                        </div>
                    </div>
                ))}

            </div>
            {/* Dots Indicators */}
            <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleSlideChange(index)}
                        className={`w-3 h-3 rounded-full ${currentIndex === index ? "bg-[#7829e3]" : "bg-gray-400"}`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
