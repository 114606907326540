import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const PasswordManager = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Secure Access Framework: Secure and Modern Self-Service Password Management
                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Streamline password management with a secure, user-friendly self-service solution. Empower users to handle their own password needs, reducing reliance on helpdesk support. This approach not only enhances user convenience but also delivers substantial time and cost savings for your organization.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Empower Users with Self-Service Password Creation
                        </h2>
                        <p className="text-gray-600 text-lg">

                            Enable users to create their own passwords, giving them greater control over their identity while reducing the demand for password recovery support. Security remains uncompromised, as all passwords adhere to organizational policies. Additionally, users can choose from various AMFA and passwordless authentication options to further enhance protection.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/password-1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/password-2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Customizable Password Policy for Enhanced Security
                        </h2>
                        <p className="text-gray-600 text-lg">

                            Strengthen your organization’s data security with 1Auth Password Manager’s configurable password policy solution. Define rules for password length, complexity, and renewal frequency to ensure that all user passwords consistently meet robust security standards, minimizing vulnerabilities and enhancing overall protection.

                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Self-Service Password Reset for Cost Efficiency and Security
                        </h2>
                        <p className="text-gray-600 text-lg">

                            With 1Auth's self-service password manager, users can independently reset their passwords as needed, reducing the reliance on helpdesk support. This approach not only saves costs—up to $70 per recovery request, according to Forrester—but also enhances security by incorporating the user’s selected AMFA factor for verification.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/password-3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/password-4.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Self-Service Password Reset for Cost Efficiency and Security
                        </h2>
                        <p className="text-gray-600 text-lg">

                            With 1Auth's self-service password manager, users can independently reset their passwords as needed, reducing the reliance on helpdesk support. This approach not only saves costs—up to $70 per recovery request, according to Forrester—but also enhances security by incorporating the user’s selected AMFA factor for verification.


                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Smart Analytics for Password Management
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth's Smart Analytics dashboard for Password Manager offers detailed visibility into user activity related to password creation and recovery. Integrated with the AMFA dashboard, it provides valuable insights into the authentication factors in use and emerging preference trends across your organization, enabling informed decision-making.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/password-5.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>

        </>
    )
}

export default PasswordManager