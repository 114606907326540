// import './Header.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/imgs/logo.svg';
import authtxt from '../../assets/imgs/authtxt.png';
import React, { useEffect, useRef, useState } from "react";
// import { useLocation } from "react-router-dom";

import { useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Header = () => {
    const location = useLocation();

    const menuRef = useRef(null);

    const [isScrolled, setIsScrolled] = useState(false);

    // const location = useLocation();

    const headerColor = () => {
        if (location.pathname.includes("contact") || location.pathname.includes("tryit")) {
            return 'gradient';
        }
        else if (location.pathname === "/" || location.pathname.includes("home")) {
            return 'black';
        } else {
            return `black`;
        }
    };

    useEffect(() => {
        setMenuOpen(false);
        setActiveMenu(null);
    }, [location.pathname]);



    const [menuOpen, setMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null); // Tracks the active menu for subcategories
    const toggleMenu = (menu) => {
        setActiveMenu(activeMenu === menu ? null : menu);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            once: true, // Whether animation should happen only once
        });
        AOS.refresh();

        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
                setActiveMenu(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className={`fixed left-0 z-[1000000000] duration-500 transition-all w-full ${!isScrolled && headerColor() !== 'gradient' ? "bg-transparent bg-${headerColor()} bg-opacity-30" : (headerColor() !== "white" && headerColor() !== "gradient" || headerColor() === "gradient") ? "main-banner2" : ""}   nav-sticky  backdrop-blur-md pt-1 pb-2
        shadow-md px-6 lg:px-24`}>
            <nav className="max-w-[90vw] mx-auto px-4 py-3 flex justify-between items-center" data-aos="fade-down" data-aos-anchor-placement="bottom-center" ref={menuRef}>
                <div className="nav-logo x-auto">
                    <Link to="/home" className="flex items-center justify-center" href="#">
                        <img src={logo} alt="1Auth" style={{ height: "50px" }} />
                        <span className={`ml-2 text-2xl font-bold pt-0.5 ${headerColor() === "white" ? "text-gray-700" : "text-white"}`}>1Auth</span>
                        {/* <img src={authtxt} alt="1Auth"  style={{height:"50px"}} /> */}
                    </Link>
                </div>

                <ul className="hidden md:flex relative">
                    <li className="nav-item native group peer">
                        <Link className={`px-4 py-2 ${headerColor() === "white" ? "text-gray-700" : "text-white"}  hover:text-[#7829e3] font-medium text-xl peer`}>Products
                            <i
                                className="fa fa-solid fa-angle-down text-paragraph dark:text-white ml-1 group-hover:rotate-180 duration-500 mt-1"></i>
                        </Link>
                        <div
                            className="absolute left-0 top-full w-full bg-white shadow-xl rounded-b-lg p-6 z-50 hidden group-hover:flex peer-hover:flex">
                            <div className="">
                                <Link to="ssoidp">
                                    <div className="p-4 hover:bg-gray-50 rounded-lg group">
                                        <h3 className="font-semibold text-lg text-gray-800">SSO/IDP</h3>
                                        <p className="text-gray-600 mt-1">DIY single sign-on for all your applications.</p>
                                    </div>
                                </Link>
                                <Link to="accessmanager">
                                    <div className="p-4 hover:bg-gray-50 rounded-lg">
                                        <h3 className="font-semibold text-lg text-gray-800">Access Manager</h3>
                                        <p className="text-gray-600 mt-1">Simplified, powerful access management for enhanced
                                            security &amp;
                                            productivity.</p>
                                    </div>
                                </Link>
                                <Link to="amfa">
                                    <div className="p-4 hover:bg-gray-50 rounded-lg">
                                        <h3 className="font-semibold text-lg text-gray-800">AMFA</h3>
                                        <p className="text-gray-600 mt-1">Reinforced security that goes far beyond passwords.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to="clouddirectory">
                                    <div className="p-4 hover:bg-gray-50 rounded-lg">
                                        <h3 className="font-semibold text-lg text-gray-800">Cloud Directory</h3>
                                        <p className="text-gray-600 mt-1">Highly secure blockchain-powered User Identity Store.
                                        </p>
                                    </div>
                                </Link>
                                <Link to="passwordmanager">

                                    <div className="p-4 hover:bg-gray-50 rounded-lg">
                                        <h3 className="font-semibold text-lg text-gray-800">Password Manager</h3>
                                        <p className="text-gray-600 mt-1">Modern and secure self-service password management.
                                        </p>
                                    </div>
                                </Link>
                                <Link to="userlifecycle">
                                    <div className="p-4 hover:bg-gray-50 rounded-lg">
                                        <h3 className="font-semibold text-lg text-gray-800">User Lifecycle Manager</h3>
                                        <p className="text-gray-600 mt-1">Streamlined automation all the way from on-boarding to
                                            exit.
                                        </p>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </li>


                    <li className="nav-item relative group">
                        <Link className={`px-4 py-2 ${headerColor() === "white" ? "text-gray-700" : "text-white"}  hover:text-[#7829e3] font-medium text-xl peer`}
                        >Verticals
                            <i
                                className="fa fa-solid fa-angle-down text-paragraph dark:text-white ml-1 group-hover:rotate-180 duration-500 mt-1"></i>

                        </Link>
                        <div
                            className="dropdown absolute left-0 top-full w-[400px] bg-white shadow-xl rounded-b-lg p-6 z-50  hidden group-hover:block">
                            <div className="grid grid-cols-2 gap-4">
                                <Link to="data-governance-ksa-laws"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Data Governance (KSA)</Link>
                                <Link to="higheredu"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Higher
                                    Education</Link>
                                <Link to="itesbpo"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">ITes
                                    &amp;
                                    BPO</Link>
                                <Link to="manufacture"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Manufacturing</Link>
                                <Link to="retail"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Retail</Link>
                                <Link to="finserv"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">FinServ</Link>
                                <Link to="healthcare"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Healthcare</Link>
                                <Link to="technology"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Technology</Link>
                                <Link to="ngo"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">NGOs</Link>
                                <Link to="publicsector"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Public
                                    Sector</Link>
                                <Link to="tourism"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Tourism</Link>
                                {/* <Link to="graph"
                                    className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Plot</Link> */}

                            </div>
                        </div>
                    </li>




                    <li className="nav-item group">
                        <Link to="contact" className={`px-4 py-2 ${headerColor() === "white" ? "text-gray-700" : "text-white"}  hover:text-[#7829e3] font-medium text-xl peer`}>Contact Us
                        </Link>
                    </li>

                    <li className="nav-item group">
                        <Link to="tryit"
                            className="ml-10 px-6 py-2.5 bg-[#7829e3] text-white font-medium rounded-lg hover:bg-[#7829e3] transition-colors">
                            Try it
                        </Link>
                    </li>
                </ul>

                <div className="md:hidden px-2.5">
                    <button
                        onClick={() => {
                            setMenuOpen(!menuOpen)
                            toggleMenu('')
                        }
                        }
                        className="focus:outline-none"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>
                </div>
                {menuOpen && (
                    <div className="absolute top-full left-0 w-full bg-white px-4 py-2">
                        <a href="#" className="block py-2 text-sm" onClick={() => toggleMenu("products")}>
                            <span className='text-lg font-semibold'>Products</span>
                            {activeMenu === "products" && (
                                <div
                                    className="">
                                    <div className="">
                                        <Link to="ssoidp">
                                            <div className="p-4 hover:bg-gray-50 rounded-lg group">
                                                <h3 className="font-semibold text-lg text-gray-800">SSO/IDP</h3>
                                                <p className="text-gray-600 mt-1">DIY single sign-on for all your applications.</p>
                                            </div>
                                        </Link>
                                        <Link to="accessmanager">
                                            <div className="p-4 hover:bg-gray-50 rounded-lg">
                                                <h3 className="font-semibold text-lg text-gray-800">Access Manager</h3>
                                                <p className="text-gray-600 mt-1">Simplified, powerful access management for enhanced
                                                    security &amp;
                                                    productivity.</p>
                                            </div>
                                        </Link>
                                        <Link to="amfa">
                                            <div className="p-4 hover:bg-gray-50 rounded-lg">
                                                <h3 className="font-semibold text-lg text-gray-800">AMFA</h3>
                                                <p className="text-gray-600 mt-1">Reinforced security that goes far beyond passwords.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="clouddirectory">
                                            <div className="p-4 hover:bg-gray-50 rounded-lg">
                                                <h3 className="font-semibold text-lg text-gray-800">Cloud Directory</h3>
                                                <p className="text-gray-600 mt-1">Highly secure blockchain-powered User Identity Store.
                                                </p>
                                            </div>
                                        </Link>
                                        <Link to="passwordmanager">

                                            <div className="p-4 hover:bg-gray-50 rounded-lg">
                                                <h3 className="font-semibold text-lg text-gray-800">Password Manager</h3>
                                                <p className="text-gray-600 mt-1">Modern and secure self-service password management.
                                                </p>
                                            </div>
                                        </Link>
                                        <Link to="userlifecycle">
                                            <div className="p-4 hover:bg-gray-50 rounded-lg">
                                                <h3 className="font-semibold text-lg text-gray-800">User Lifecycle Manager</h3>
                                                <p className="text-gray-600 mt-1">Streamlined automation all the way from on-boarding to
                                                    exit.
                                                </p>

                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            )}
                        </a>
                        <a href="#" className="block py-2 text-sm" onClick={() => toggleMenu("verticals")}>
                            <span className='text-lg font-semibold'>Verticals</span>
                            {activeMenu === "verticals" && (
                                <div
                                    className="">
                                    <div className="grid grid-cols-2 gap-4">
                                        <Link to="data-governance-ksa-laws"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Data Governance (KSA)</Link>

                                        <Link to="higheredu"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Higher
                                            Education</Link>
                                        <Link to="itesbpo"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">ITes
                                            &amp;BPO</Link>
                                        <Link to="manufacture"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Manufacturing</Link>
                                        <Link to="retail"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Retail</Link>
                                        <Link to="finserv"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">FinServ</Link>
                                        <Link to="healthcare"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Healthcare</Link>
                                        <Link to="technology"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Technology</Link>
                                        <Link to="ngo"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">NGOs</Link>
                                        <Link to="publicsector"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Public
                                            Sector</Link>
                                        <Link to="tourism"
                                            className="p-2 hover:bg-gray-50 rounded-lg text-gray-700 hover:text-[#7829e3]">Tourism</Link>
                                    </div>
                                </div>

                            )}
                        </a>
                        <Link to="contact" className="block py-2 text-sm">
                            <span className='text-lg font-semibold'>Contact Us</span>
                        </Link>
                        <Link to="contact"
                            className="block
                             bottom-2 transform  text-center px-6 py-2.5 bg-[#7829e3] text-white font-medium rounded-lg hover:bg-[#7829e3] transition-colors">
                            Try it
                        </Link>
                    </div>
                )}
            </nav>
        </header>
    )
}
export default Header;
