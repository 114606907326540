import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const Manufacture = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Manufacturing



                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Boost operational efficiency while securing intellectual property.


                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            As manufacturing becomes increasingly digitized, access to applications and data is required across all levels, including the shop floor. Implementing robust systems to prevent unauthorized access without disrupting productivity is essential. An IAM solution allows you to enhance security while maintaining seamless access, ensuring both protection and efficiency.

                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Data Privacy at Scale
                        </h2>
                        <p className="text-gray-600 text-lg">
                            In today's digital era, every stage of product lifecycle management (PLM)—from market research to design, production, customer service, and retirement—relies on applications handling sensitive intellectual property. Protecting this critical data on design, engineering, sales, and service is essential to maintaining competitiveness.

                            <br></br>
                            Passwords often represent a vulnerability, making it crucial to implement systems that extend beyond traditional password-based security. 1Auth’s adaptive multi-factor authentication (AMFA) adds an extra layer of protection by detecting and responding to unfamiliar activities, such as logins from unknown devices, unusual times, or new locations.

                            <br></br>

                            Mitigate the risk of compromised credentials by enabling authentication factors beyond passwords, including TOTP, FaceID, TouchID, and even passwordless options, with 1Auth AMFA.


                            <br></br>
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/rb_1310.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/rb_2535.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Regulated Access to Critical Resources
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Factory workers often require access to essential applications, such as CAD designs, manufacturing execution systems, quality management systems, and ERP platforms. However, it is crucial to limit access to these systems to prevent potential misuse.


                            <br></br>
                            With 1Auth, access can be restricted to specific days and hours, ensuring that attempts to access systems outside of scheduled work hours or during holidays are blocked. Additionally, since remote access is rarely needed, IP-based restrictions can be applied to allow access only from within your facility’s network.
                            <br></br>
                            Enhance security and maintain operational efficiency by leveraging 1Auth’s time-based and IP-based access controls for users across different roles.


                            <br></br>

                        </p>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Simplified Login for Enhanced Productivity
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Factory workers often face challenges managing multiple credentials for accessing various applications, compounded by the time lost recovering forgotten passwords through IT support. This impacts both production efficiency and IT resources.


                            <br></br>
                            1Auth addresses these challenges with a multi-pronged approach. Single sign-on (SSO) enables workers to access multiple applications using a single set of credentials, simplifying the login process. Additionally, the self-service password management solution empowers users to manage their passwords independently, reducing the burden on IT teams. For even greater efficiency, passwordless authentication eliminates the need for passwords entirely, streamlining access further.


                            <br></br>
                            Enhance productivity for your workers and IT team with 1Auth’s SSO, IdP, and Password Manager solutions.


                            <br></br>
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/rb_26658.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>



        </>
    )

}



export default Manufacture;