import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const AMFA = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Authentication Repository: Enhanced Security Beyond Passwords

                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Strengthen your organization’s security with advanced authentication factors that are simple, efficient, and cost-effective to implement. Mitigate the vulnerabilities of password-based protection by deploying step-up authentication, ensuring an optimal balance between robust security and seamless access for users.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Passwordless Authentication for Seamless Security
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth offers a fast, secure, and user-friendly passwordless authentication solution. By removing passwords entirely from the login process, this approach eliminates vulnerabilities such as weak or reused passwords while enhancing security. Users benefit from a significantly improved and hassle-free login experience.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/amfa-1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/amfa-2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Comprehensive Authentication Factors Library
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth AMFA (Adaptive Multi-Factor Authentication) provides a versatile range of secure and convenient authentication options, giving you full control over access security. Select from advanced methods such as Blockchain QR, FaceID, TouchID, Mobile Push, Email, SMS, or even integrate your own custom factors, ensuring unparalleled flexibility to meet your organization’s unique needs.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Smart Analytics for Comprehensive Insights
                        </h2>
                        <p className="text-gray-600 text-lg">

                            The intuitive Smart Analytics dashboard for 1Auth AMFA offers detailed visibility into usage patterns and user preferences for authentication factors. Additionally, its seamless integration with Smart Analytics across other 1Auth products provides a holistic, organization-wide view for informed decision-making.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/amfa-3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/amfa-4.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Bring-Your-Own-Factor for Maximum Flexibility
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth AMFA enables seamless integration of third-party authentication factors already in use at your organization or any additional factor not currently offered by 1Auth. This flexible approach ensures you can customize your security framework while maintaining robust protection for your organization.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Effortless Integration at Any Authentication Point
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth AMFA integrates seamlessly with any authentication point in your organization, enhancing security for all user login actions. Whether the login occurs on a remote desktop, mobile device, colleague’s laptop, or from a new location, 1Auth AMFA can be triggered to ensure robust and secure authentication.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/amfa-5.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>
        </>
    )

}



export default AMFA;