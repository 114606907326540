import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const PublicSector = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Public Sector

                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Strengthening data security for efficient governance.


                        </h2>


                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Governments, public departments, and large-scale projects face unique challenges, including managing vast volumes of private citizen data, securing field operations for numerous users, and meeting stringent regulatory compliance requirements. Identity and access management (IAM) provides a comprehensive solution to address these critical needs effectively.

                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>

            <section className="max-w-7xl mx-auto px-6 py-16 text-center" data-aos="fade-right" data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Reinforcing Public Trust Through Secure Citizen Data
                </h1>
                <p className="text-gray-600 mb-8">
                    The scale of public sector projects, encompassing data from districts, states, or entire nations, makes safeguarding citizen data a critical priority. A data breach at this level not only carries severe consequences but also poses risks to national security and erodes public trust in government systems.


                </p>
                <p className="font-semibold text-gray-800 mb-12">
                    1Auth: Ensuring Data Security on a National and Global Scale
                </p>
                <div className="flex">
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon.png" alt="Shield Icon" className="h-12 w-12" style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            1Auth enforces zero-trust security by granting users only the permissions they need, minimizing misuse and enhancing security.

                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon1.png" alt="Security Icon" className="h-12 w-12"
                                style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            Protect users at login, anytime and anywhere, by reinforcing security beyond passwords with multi-factor authentication.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon3.png" alt="Control Icon" className="h-12 w-12"
                                style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            Regulate devices, times, and locations for data and application access, ensuring secure operations for field and remote users.
                        </p>
                    </div>
                </div>
                <p className="text-gray-600 mt-12">
                    Strengthen security for employees across roles and locations using 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.


                </p>
            </section>




            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Inter-Agency Collaboration
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Public sector projects often involve multiple departments or agencies working together, but inefficiencies arise when collaboration over shared data and applications is poorly managed.
                            <br></br>

                            1Auth simplifies this by consolidating applications from different agencies onto a common platform, serving as a unified identity provider. This enables single sign-on across applications, streamlining collaboration. Additionally, 1Auth’s user directory facilitates easy management of users and roles, promoting efficient information sharing while ensuring compliance and governance.

                            <br></br>
                            Enhance collaboration across entities, applications, and users with 1Auth’s Cloud Directory and SSO & IdP.


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/public_img.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/public_img1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Enhanced Regulatory Compliance
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Governmental agencies must exemplify compliance with data privacy regulations due to the sensitive nature of the data they manage, where breaches could impact public welfare or national security. 1Auth supports regulatory compliance through:
                            <br /><br />

                            Role-Based Access Controls (RBAC): Ensuring segregation of duties and granting users only the access they need.<br /><br />

                            Data Protection Measures: Implementing granular access management and multi-factor authentication to meet strict data protection standards.<br /><br />
                            Comprehensive Audit Logs: Capturing detailed records of user activity for seamless compliance audits.<br /><br />
                            Achieve stringent compliance without compromising productivity or user experience with 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.

                            <br /><br />

                        </p>
                    </div>
                </div>
            </section>


        </>
    )

}



export default PublicSector;