import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const ITesBPO = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            ITeS & BPO

                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Balancing customer data privacy with service efficiency.


                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Outsourcing businesses face two critical challenges: safeguarding sensitive customer data and managing large teams across diverse roles. Identity and access management addresses these challenges effectively while solving various other operational complexities.


                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            The Path to Zero Trust Security


                        </h2>
                        <p className="text-gray-600 text-lg">
                            For ITeS and BPO businesses, safeguarding sensitive client information requires a Zero Trust model to close security gaps and prevent breaches. Identity and access management (IAM) is a cornerstone of this strategy, implementing least-privilege and role-based or privileged access management.

                            <br></br>
                            With large workforces, unrestricted access to customer data assets creates significant risks. Insider threats—both intentional and accidental—account for up to 60% of data breaches. 1Auth mitigates this by restricting each user’s access to only the data necessary for their role, significantly reducing the likelihood of breaches.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/ites.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/ites1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Secure Remote and Hybrid Operations
                        </h2>
                        <p className="text-gray-600 text-lg">
                            With employees and agents working from multiple locations and devices, traditional office-centric security systems fall short. Controlling when, where, and how your team accesses organizational data is crucial for maintaining access integrity and minimizing risks of fraudulent or malicious activity.

                            <br></br>

                            Granting access from any device or network increases exposure to threats. 1Auth addresses this by allowing access only from specific whitelisted IP addresses and devices. Additionally, the platform detects and flags suspicious login attempts based on unusual times or unexpected locations, enabling immediate blocking or challenge actions.

                            <br></br>

                            Strike the right balance between flexibility, productivity, and security in today’s work-from-anywhere world with 1Auth Access Manager.


                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Boosting Employee Productivity

                        </h2>
                        <p className="text-gray-600 text-lg">

                            A key productivity bottleneck arises when employees rely on IT helpdesk support for password recovery and account assistance. Forgotten passwords or locked accounts often leave users unable to work until resolved. Implementing a self-service recovery solution effectively minimizes such downtimes.
                            <br></br>
                            Another challenge is the need for employees to access multiple applications individually, requiring separate credentials and repeated logins. This not only consumes time but also increases instances of forgotten passwords or locked accounts. 1Auth addresses this by providing single sign-on, enabling access to multiple applications with one set of credentials through a single access point, simplifying the login process and enhancing productivity.
                            <br></br>
                            Empower users and enhance operational efficiency with 1Auth Password Manager and SSO & IdP.


                            <br></br>
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/ites2.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/ites3.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Administration Processes
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Managing user identity credentials for a large workforce places a significant burden on IT and admin teams. From onboarding and provisioning to role management, support, and deprovisioning during exit, every stage of the employee lifecycle demands prompt attention, consuming valuable time and resources. Improper management can also lead to security risks, such as excessive permissions or unrevoked access after an employee’s departure.
                            <br></br>
                            IT and admin teams often spend time creating user accounts, assigning roles and permissions, modifying privileges during employment, and revoking access at exit. Password recovery requests further strain their bandwidth. 1Auth simplifies these processes, enabling efficient management of user provisioning, role assignments, and deprovisioning, while reducing time spent on password support.
                            <br></br>
                            Save time, reduce costs, and enhance security with 1Auth Cloud Directory and Password Manager.

                        </p>
                    </div>
                </div>
            </section>

        </>
    )

}



export default ITesBPO;