import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const HigherEducation = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Higher Education
                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Secure and seamless access is ensured from enrollment through graduation.
                        </h2>


                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">

                            With diverse user roles, numerous applications to manage, and frequently changing access needs, identity and access management plays a vital role in maintaining secure and efficient operations across educational institutions.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Real-Time Management of User Accounts and Roles
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Efficient and secure management of users across various roles—students, faculty, administrators, and alumni—is essential for your institution.
                            <br /><br />
                            With constant role changes as students progress through programs, advance to new academic years, or graduate, managing this dynamic environment can be challenging. 1Auth addresses this with a secure, adaptable user directory and an automated provisioning and deprovisioning system, enabling one-click access permission assignments.
                            <br /><br />
                            Prepare for increasing student numbers and evolving role complexities with 1Auth’s Cloud Directory and User Lifecycle Manager, ensuring seamless account and role management.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/rb_2147772112.png" alt="App Connectors" className="w-full max-w-lg" />

                    </div>
                </div>
            </section>





            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/rb_2148074419.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Ensuring Accurate Application Access for Every User
                        </h2>
                        <p className="text-gray-600 text-lg">

                            Higher education institutions manage a variety of applications, from learning management systems to collaboration tools and faculty, student, and alumni portals. Consolidating these applications onto a single platform eliminates the need for users to manage multiple passwords or log in separately to each system.
                            <br></br>
                            Managing access permissions for a large user base manually can be both tedious and impractical. From initial provisioning at entry to deprovisioning at exit, 1Auth simplifies the process by enabling access permissions to be set for individual users or roles, ensuring efficient administrative operations.
                            <br></br>
                            Deliver secure and convenient access to users while granting administrators granular control over permissions with 1Auth’s SSO & IdP and User Lifecycle Manager.


                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Stay Secure in a Learn-From-Anywhere World                        </h2>
                        <p className="text-gray-600 text-lg">
                            As remote and hybrid learning become standard, inadequate security can lead to compromised quality and diminished trust, directly affecting your institution’s reputation. Common challenges include:
                            <ul>

                                <li>Ensuring that only authorized students access remote learning sessions.</li>
                                <li>Verifying that assignments, projects, or examinations are genuinely submitted by legitimate students.</li>
                                <li>Protecting intellectual property such as funded research, confidential data, and proprietary learning materials.</li>
                                <li>material, and so on for your institution</li>
                            </ul>
                            Strengthen authentication security and maintain trust while safeguarding your institution’s reputation with 1Auth’s AMFA and Access Manager.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/rb_2148095330.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


        </>



    )
}


export default HigherEducation;