import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';
import { Link } from 'react-router-dom';



const DataGovernance = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Data Governance (KSA)

                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            1Auth is a Single Sign-On (SSO) and Identity Access Management (IAM) tool designed to provide secure and seamless access control for organizations. Hosted on Google Cloud Platform (GCP) and deployable locally to meet demographic needs, 1Auth ensures compliance with global and regional data governance standards. This report focuses on 1Auth’s alignment with Saudi Arabia’s data governance and privacy laws, particularly the <strong className='text-white' >Personal Data Protection Law (PDPL)</strong> and related regulations. By adhering to these laws, 1Auth ensures data sovereignty, security, and compliance, making it a reliable solution for organizations operating in the Kingdom of Saudi Arabia (KSA)
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white" data-aos="fade-up" data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-full mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        {/* <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            IP-Based Access Restrictions
                        </h2> */}
                        <p className="text-gray-600 text-lg">
                            1Auth is a robust IAM solution that integrates with GCP's Identity and Access Management framework, offering fine-grained access control, context-aware policies, and automated compliance tools. Its ability to deploy locally ensures that organizations can meet data localization requirements, a critical aspect of KSA's data governance laws.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg-gray-50 py-12 px-6" data-aos="fade-down" data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">Key Features of 1Auth</h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 max-w-7xl mx-auto">
                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-lock h-8 w-8 mb-2 text-primary">
                                        <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">SSO/IDP</h3>
                            <p className="text-gray-600 mb-4">
                                Simplifies user authentication across multiple platforms.                            </p>
                            <Link to="/ssoidp"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-fingerprint h-8 w-8 mb-2 text-primary">
                                        <path d="M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4"></path>
                                        <path d="M14 13.12c0 2.38 0 6.38-1 8.88"></path>
                                        <path d="M17.29 21.02c.12-.6.43-2.3.5-3.02"></path>
                                        <path d="M2 12a10 10 0 0 1 18-6"></path>
                                        <path d="M2 16h.01"></path>
                                        <path d="M21.8 16c.2-2 .131-5.354 0-6"></path>
                                        <path d="M5 19.5C5.5 18 6 15 6 12a6 6 0 0 1 .34-2"></path>
                                        <path d="M8.65 22c.21-.66.45-1.32.57-2"></path>
                                        <path d="M9 6.8a6 6 0 0 1 9 5.2v2"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Fine-Grained Access Control</h3>
                            <p className="text-gray-600 mb-4">
                                Enables role-based permissions and context-aware policies.
                            </p>
                            <Link to="/accessmanager"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-cloud h-8 w-8 mb-2 text-primary">
                                        <path d="M18 19H6a4 4 0 0 1-1.33-7.76A5 5 0 0 1 16 6a5 5 0 0 1 4 8h-2"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Local Deployment</h3>
                            <p className="text-gray-600 mb-4">
                                Supports data localization to comply with regional laws
                            </p>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-user h-8 w-8 mb-2 text-primary">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Audit Trails</h3>
                            <p className="text-gray-600 mb-4">
                                Provides comprehensive logs for compliance monitoring</p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-white py-12 px-6" data-aos="fade-right" data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <div className="text-center mb-8 max-w-7xl mx-auto ">
                    <h2 className="text-3xl font-bold text-gray-800">Saudi Arabia's Data Governance Framework</h2>
                    <p className='mt-10'> Saudi Arabia's data governance framework is anchored in the <strong>Personal Data Protection Law (PDPL)</strong>,
                        which came into effect on September 14, 2023, with a compliance grace period until September 14, 2024. The PDPL is enforced by the <strong>Saudi Data and Artificial Intelligence Authority (SDAIA)</strong> and emphasizes data sovereignty, privacy, and security</p>
                </div>
                <div className="max-w-7xl mx-auto">
                    <h3 className='font-bold text-xl'>Key Requirements of the PDPL</h3>
                    <ul className='mt-3 space-y-2'>
                        <li>
                            <strong>1.	Data Localization:</strong> Personal data must be stored and processed within KSA unless explicit permission is granted for cross-border transfers.
                        </li>
                        <li>
                            <strong>2.	Consent Management:</strong> Organizations must obtain explicit consent from data subjects before collecting or processing their data.
                        </li>
                        <li>
                            <strong>3.	Data Subject Rights:</strong> Individuals have the right to access, correct, and request the deletion of their data.
                        </li>

                        <li>
                            <strong>4.	Data Minimization:</strong>Only the minimum necessary data should be collected and retained.
                        </li>
                        <li>
                            <strong>5.	Security Measures:</strong> Organizations must implement robust security measures to protect personal data.
                        </li>
                    </ul>
                </div>
                <div className="text-center mb-8 max-w-7xl mx-auto mt-10">
                    <h2 className="text-3xl font-bold text-gray-800">1Auth’s Compliance with KSA Data Governance Laws</h2>
                </div>

                <div className="max-w-7xl mx-auto">
                    <ul className='mt-3 space-y-5'>
                        <li>
                            <strong>1.Data Localization and Sovereignty:</strong><br></br>
                            1Auth supports local deployment, ensuring that personal data is stored and processed within KSA. This aligns with the PDPL’s data localization requirements and enhances national security by keeping sensitive data within the country’s borders.
                        </li>
                        <li>
                            <strong>2.	Consent Management:</strong><br></br>
                            1Auth integrates consent management features, allowing organizations to obtain and manage user consent effectively. This ensures compliance with the PDPL's requirement for explicit consent before data collection and processing.
                        </li>
                        <li>
                            <strong>3.	Data Subject Rights:</strong> <br></br>
                            1Auth provides tools for data subjects to exercise their rights, including:
                            <ul className='ml-5'>
                                <li><strong>•	Access Requests:</strong> Users can request a copy of their personal data.</li>
                                <li><strong>•	Correction Requests:</strong> Users can update or correct their data.</li>
                                <li><strong>•	Deletion Requests: </strong>Users can request the deletion of their data when no longer needed.</li>
                            </ul>
                        </li>

                        <li>
                            <strong>4.	Data Minimization:</strong><br></br>
                            1Auth enforces data minimization by allowing organizations to collect only the necessary data for specific purposes. It also includes automated data retention and deletion policies to ensure compliance with the PDPL’s data minimization requirements.
                        </li>
                        <li>
                            <strong>5.	Security Measures:</strong><br></br> 1Auth leverages GCP’s advanced security features, including encryption, multi-factor authentication (MFA), and context-aware access controls. These measures protect personal data from unauthorized access and breaches, fulfilling the PDPL’s security requirements.
                        </li>
                        <li>
                            <strong>6.Audit and Compliance Monitoring:</strong><br></br>1Auth provides comprehensive audit trails and compliance monitoring tools, enabling organizations to track access and modifications to personal data. This supports compliance with the PDPL’s requirement for periodic audits and assessments.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="py-16 bg-gray-50">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-full mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        {/* <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            IP-Based Access Restrictions
                        </h2> */}
                        <p className="text-gray-600 text-lg">
                            1Auth is a comprehensive IAM solution that aligns with Saudi Arabia’s data governance and privacy laws. By supporting data localization, enforcing consent management, and providing robust security measures, 1Auth ensures compliance with the PDPL and related regulations. Its integration with GCP’s IAM framework further enhances its capabilities, making it an ideal choice for organizations operating in KSA.<br></br><br></br>
                            For organizations seeking a secure and compliant IAM solution, 1Auth offers the tools and flexibility needed to navigate the complexities of KSA’s data governance landscape.


                        </p>
                    </div>
                </div>
            </section>



        </>
    )

}



export default DataGovernance;