import React, { useState } from "react";

const ContactUS = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    interests: [],
  });

  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        interests: checked
          ? [...prev.interests, value]
          : prev.interests.filter((interest) => interest !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    setMessage("");
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch("https://1auth.blitz-work.com/mail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (result.success) {
        setMessage("Email sent successfully!");
      } else {
        setMessage("Failed to send email.");
      }
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while sending the email.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center sm:pt-28 md:pt-2">
      <div className="container mx-auto flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Get in touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

            </div>

            <p className="mb-4">I would like to know more about your:</p>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              {["SSO/ IDP", "Password Manager", "AMFA", "Cloud Directory", "Access Manager"].map(
                (item) => (
                  <label key={item} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      value={item}
                      checked={formData.interests.includes(item)}
                      onChange={handleChange}
                    />
                    <span>{item}</span>
                  </label>
                )
              )}
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-4">
              <div className="w-full ">
                <input
                  type="email"
                  name="email"
                  placeholder="Work Email"
                  className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="w-full ">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
              </div>


            </div>
            <button
              type="submit"
              className="px-6 py-2 bg-purple-600 text-white font-medium rounded-lg hover:bg-purple-700 transition-colors"
            >
              Send
            </button>
          </form>
          {message && <p className="mt-4">{message}</p>}
        </div>

        {/* Right Section - Contact Information */}
        <div className="w-full md:w-1/2 p-8 bg-purple-50">
          <h2 className="text-3xl font-bold mb-4">Contact us</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-bold">INDIA</h3>
              <p className="text-gray-700">
                No 22, East Jones Road, Saidapet, Chennai - 15
                Tamil Nadu, India.
              </p>
              <p className="text-gray-700">
                📞 +91 9389403940
              </p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
            <div>
              <h3 className="text-xl font-bold">Germany</h3>
              <p className="text-gray-700">
                N 7, 9, Baden-Württemberg, 68161, Germany
              </p>
              <p className="text-gray-700">
                📞 +49 9894849598
              </p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
            <div>
              <h3 className="text-xl font-bold">United Kingdom</h3>
              <p className="text-gray-700">📞 +44 9799389499</p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ContactUS;