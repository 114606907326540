import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const Tourism = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Tourism



                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Enhanced Security and Efficiency for Better Customer Experiences


                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Whether managing an airline, a hotel chain, or a travel portal, tourism businesses rely on numerous technology platforms. Despite this complexity, protecting customer financial, travel, and personal information, maintaining operational efficiency, and managing seamless integrations with third-party vendors are essential to delivering exceptional customer experiences.





                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Data privacy at scale
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Operational data like inventory, dynamic pricing rules, and vendor contracts, combined with sensitive customer information such as identity proofs, financial details, and travel plans, form the backbone of your business. A data breach involving any of these can severely impact your finances and reputation.<br /><br />

                            1Auth safeguards your data by enforcing granular access permissions, advancing your zero-trust security strategy. This protection is bolstered by multi-factor authentication and device- and IP-based access restrictions, mitigating the risk of fraudulent credential misuse.
                            <br /><br />
                            Secure corporate and customer data across all platforms and users with 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.<br /><br />
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/tr.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/tr1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Access for Exceptional Travel Experiences
                        </h2>
                        <p className="text-gray-600 text-lg">
                            In the competitive travel industry, customer satisfaction drives success. Fast service delivery and responsiveness are key to delighting travelers, and Identity and Access Management (IAM) plays a crucial role in achieving this.


                            <br /><br />

                            1Auth simplifies access for users, especially those in customer-facing roles, ensuring quick and seamless access to essential applications. This enables faster service delivery, shorter wait times for customers—whether for hotel check-ins or flight booking changes—and uninterrupted system availability through enhanced security. The result is happier customers, stronger loyalty, and increased repeat business—essential for thriving in the service-driven travel sector.
                            <br /><br />
                            Deliver secure, efficient operations and keep customers coming back with 1Auth’s SSO & IdP.
                            <br /><br />

                        </p>
                    </div>
                </div>
            </section>


        </>
    )

}



export default Tourism;