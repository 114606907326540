import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const SsoIdp = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            All-in-One Authentication for all your applications.
                            <span
                                className="text-gray-200 italic inline-block border border-gray-700 rounded px-2 py-1 mt-2">
                                1Auth
                            </span>
                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Effortlessly access all your applications with All-in-One Authentication, seamlessly integrating 100+ apps through a Unified Directory Platform and advanced authentication.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Over 500 Pre-built Application Connectors
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Deployment delays in single sign-on implementation have been eliminated. A comprehensive library of over 500 connectors to widely-used SaaS applications is provided, with continuous expansions to address evolving needs. Ready-to-use integrations are made available for virtually all applications critical to workplace operations.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/sso_image1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/sso-img2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Effortless One-Click Configuration
                        </h2>
                        <p className="text-gray-600 text-lg">

                            The use of pre-built application connectors eliminates the need to create custom integrations for each application, streamlining the process significantly. These connectors are designed for simplicity and ease of use. Configuring your SSO with 1Auth requires only the selection of the applications to integrate, enabling setup to be completed within minutes.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Extensive Protocol Compatibility
                        </h2>
                        <p className="text-gray-600 text-lg">

                            Applications utilize various protocols for user authentication through external identity providers (IdPs). To uphold our dedication to seamless integration, 1Auth ensures compatibility with all major federated authentication protocols, including CAS, SAML, OpenID, WSFed, and blockchain-enabled authentication, accommodating the needs of your organization effectively.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/ssoPage_Imag2.jpeg" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/sso-img4.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Optimizing Application Licenses

                        </h2>
                        <p className="text-gray-600 text-lg">

                            Through centralized identity management, comprehensive monitoring of application usage across the organization is facilitated. These detailed analytics allow businesses to refine their licensing strategies, ensuring subscriptions and purchases are effectively aligned with actual needs, thereby achieving notable cost reductions.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Smart Analytics Overview
                        </h2>
                        <p className="text-gray-600 text-lg">

                            An intuitive administrative dashboard is provided by 1Auth, consolidating all applications and users into a unified interface. The Smart Analytics feature for 1Auth SSO and IdP delivers insights such as user-to-application mapping and application license usage statistics. Additionally, it integrates effortlessly with Smart Analytics for other 1Auth products, offering a comprehensive, organization-wide perspective.


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/sso-img5.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default SsoIdp;