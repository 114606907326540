import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const CloudDirectory = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Blockchain-Powered User Identity Store for Unmatched Security
                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Leverage blockchain technology to secure user credentials with tamper-proof, unhackable protection. Blockchain-based credentials eliminate risks of compromised passwords and user impersonation, ensuring they cannot be altered, faked, or spoofed, providing an unparalleled level of security.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Unified and Secure Single Identity Store
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth Cloud Directory serves as a comprehensive identity store for all your needs. Whether establishing a user directory for the first time or consolidating multiple existing directories, 1Auth offers a unified, always-on, and highly secure solution. Designed specifically for the cloud, it seamlessly provides identity credentials for all your enterprise SaaS applications.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/cd-cont-1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/cd-cont-2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Secure Blockchain Ledger for Uncompromised Protection

                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth employs a tamper-proof, decentralized, and immutable ledger to safeguard user credentials with the robust security of blockchain technology. By removing central points of vulnerability, the blockchain ledger ensures absolute integrity of identity credentials while remaining cost-efficient, providing unparalleled protection against breaches.


                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Highly Available Identity Data with Blockchain Technology
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth Cloud Directory leverages blockchain technology to provide unparalleled data availability with no single points of failure. Identity records are immutable and secure, ensuring they cannot be altered or deleted. At the same time, they remain readily accessible for verification or viewing across all your applications, guaranteeing reliability and integrity.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/cd-cont-3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/timer.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Effortless and Flexible Integrations
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth Cloud Directory offers seamless integration within the 1Auth ecosystem, other identity stores, IAM or SSO platforms, and directly with your applications. With an intuitive self-service platform, Cloud Directory can be quickly configured to deliver identity credentials whenever and wherever needed, ensuring flexibility and ease of setup in just minutes.

                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Smart Analytics for Streamlined Account Governance
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth's Smart Analytics dashboard for Cloud Directory delivers comprehensive account governance reconciliation reports. These insights map user access across applications, ensuring that each user has appropriate access to the right applications. This proactive approach reduces the time and costs associated with conducting annual audits of application access privileges.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/cd-cont-4.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>

        </>
    )
}

export default CloudDirectory