import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const Retail = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Retail
                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Fast and Secure Access Management for Streamlined Retail Operations</h2>


                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Whether managing large chains, standalone supermarkets, or e-commerce stores, efficient operations, clear communication between management and staff, and empowered customer-facing teams are essential for success. Retail businesses rely on various applications to achieve these goals, and identity and access management (IAM) serves as the backbone that unifies and secures these processes.


                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/retail.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Access Management for Streamlined Retail Operations
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Retail operations rely on applications managing inventory, supply chain logistics, vendor management, payment processing, and more. However, not all users require access to every system. Many users may not even be employees—they could be partners, such as manufacturers, distributors, or shipping agents. Granting excessive access in such environments can create significant security vulnerabilities.


                            <br></br>
                            1Auth simplifies role-based access control (RBAC) and granular permission management across your organization. It offers a secure user directory to manage users across locations, single sign-on for seamless access, and a centralized platform for managing roles and permissions.


                            <br></br>

                            <strong> Ensure easy access for users and streamlined centralized control for administrators with 1Auth’s Cloud Directory, SSO & IdP, and User Lifecycle Manager.

                            </strong>


                        </p>
                    </div>

                </div>
            </section>




            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 text-center" data-aos="fade-down">

                    <h1 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                        Effortless and effective user lifecycle management
                    </h1>
                    <p className="text-gray-600 text-lg max-w-4xl mx-auto">
                        With large numbers of locations and significantly large corporate operations, managing the different
                        stages of
                        the user lifecycle in the retail industry is hugely complex. From onboarding through employee operations
                        to exit,
                        it is often IAM that ensures your users are able to work efficiently in their roles.
                    </p>
                    <h2 className="text-2xl lg:text-3xl font-semibold text-gray-800 mt-8">
                        1Auth makes every stage of retail user lifecycle management seamless.
                    </h2>
                </div>


                <div
                    className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto px-6 lg:px-12 text-center">

                    <div data-aos="fade-up">
                        <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto">
                            <img src="/banner-imgs/ic.png" style={{ height: "32px" }} />
                        </div>
                        <p className="mt-4 text-gray-600">
                            Seamless Management of the Retail User Lifecycle


                        </p>
                    </div>


                    <div data-aos="fade-up">
                        <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto">
                            <img src="/banner-imgs/ic1.png" style={{ height: "32px" }} />
                        </div>
                        <p className="mt-4 text-gray-600">
                            Managing the user lifecycle in retail involves unique challenges due to the vast number of locations and complex corporate operations. From onboarding to daily tasks and offboarding, Identity and Access Management (IAM) is essential for enabling users to work efficiently and securely.



                        </p>
                    </div>


                    <div data-aos="fade-up">
                        <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto">
                            <img src="/banner-imgs/ic2.png" style={{ height: "32px" }} />
                        </div>
                        <p className="mt-4 text-gray-600">
                            1Auth simplifies every phase of user lifecycle management in retail, from seamless onboarding to efficient daily operations and secure offboarding, ensuring consistency and security across all locations.




                        </p>
                    </div>


                    <div data-aos="fade-up">
                        <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto">
                            <img src="/banner-imgs/ic3.png" style={{ height: "32px" }} />
                        </div>
                        <p className="mt-4 text-gray-600">
                            Revoking all access when an employee leaves the organization is simplified with 1Auth’s user directory, making the process quick and efficient with just a few clicks.


                        </p>
                    </div>
                </div>


                <div className="mt-12 text-center max-w-4xl mx-auto" data-aos="fade-up">
                    <p className="text-gray-600">
                        Effortlessly manage your large, distributed retail workforce with 1Auth’s Cloud Directory, SSO & IdP, and Password Manager, ensuring seamless and secure operations.


                    </p>
                </div>
            </section>



        </>
    )

}



export default Retail;