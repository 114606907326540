import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const Technology = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Technology
                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Ensuring secure productivity in a rapidly evolving landscape.
                        </h2>


                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            For businesses providing IT services or technology products, delivering secure solutions to customers is paramount. Equally critical is maintaining robust security within your own operations. From server credentials and codebases to proprietary tools, processes, and customer data, the tech industry faces significant financial and reputational risks in the event of a data breach.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Zero-Trust Software Development



                        </h2>
                        <p className="text-gray-600 text-lg">
                            Maintaining strict control over resource access throughout the software development lifecycle is essential. Granting unnecessarily broad access increases the risk of misuse and amplifies the potential damage during a breach.


                            <br /><br />

                            1Auth advances zero-trust security with role-based access control (RBAC), ensuring tailored access for architects, engineers, testers, and infrastructure specialists. This least-privilege approach is further strengthened by 1Auth’s multi-factor authentication and access restriction solutions, delivering a comprehensive security framework for your tech firm.


                            <br /><br />
                            Enhance internal and external security with 1Auth’s User Lifecycle Manager, AMFA, and Access Manager.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/tech.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/tech1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Faster, Smarter Internal Operations
                        </h2>
                        <p className="text-gray-600 text-lg">
                            Technology companies thrive on applications spanning collaboration, project management, coding, design, testing, automation, deployment, and security. However, managing permissions for large teams can overwhelm administrators, and individual logins for numerous applications drain user productivity.

                            <br /><br />

                            1Auth streamlines user access through single sign-on, placing every application just a click away. This eliminates the need for multiple logins and credentials, saving valuable time. For administrators, automated provisioning and role-based access permissions not only enhance security but also significantly boost operational efficiency.<br /><br />

                            Streamline development and delivery with improved productivity in access and administration using 1Auth’s User Lifecycle Manager and SSO & IdP.<br /><br />

                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Collaboration for a Superior Customer Experience

                        </h2>
                        <p className="text-gray-600 text-lg">
                            Effective collaboration between clients and development teams is a critical aspect of the software development process. However, poorly managed access control often creates unnecessary barriers, disrupting communication and productivity.<br /><br />

                            1Auth’s single sign-on simplifies access for your customers, making activities like viewing backlog lists, participating in scrum calls, selecting UI options, or reviewing beta versions effortless and accessible with a single click. These streamlined interactions lead to greater satisfaction and a stronger customer experience.<br /><br />

                            Enhance satisfaction, foster loyalty, and deliver exceptional experiences with 1Auth’s SSO & IdP.<br /><br />


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/tech2.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>



        </>
    )

}



export default Technology;