import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const AccessManager = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">
                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Advanced Access Security System: Streamlined Access Management for Modern Security

                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            Protect applications and data seamlessly in today's work-from-anywhere environment. Extend security to user devices and locations, ensuring secure, efficient access.
                            With Credential-Free Authentication, the Advanced Access Security System provides cutting-edge protection, surpassing traditional methods for a modern, hassle-free experience.
                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            IP-Based Access Restrictions
                        </h2>
                        <p className="text-gray-600 text-lg">


                            1Auth Access Manager allows administrators to define approved IP address whitelists, ensuring that access to organizational applications is limited to authorized locations, such as offices or user homes. Additionally, blacklists can be created to block access from specific IP addresses, providing enhanced security and control. This ensures applications and data remain accessible only from trusted locations, strengthening access management.

                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/am-cont-1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/am-cont-2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Device-Based Access Restrictions

                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth employs an agent to capture the serial number and BIOS UUID of each user’s device, linking these identifiers to the user profile. This mechanism ensures that only registered devices associated with a specific user profile can access the account. During login attempts, 1Auth verifies both user credentials and device information, blocking access from unrecognized devices to enhance security.

                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Location-Based Access Restrictions

                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth Access Manager enables administrators to define specific geographic coordinates, ensuring organizational applications can only be accessed from authorized locations. This functionality provides robust control by blocking access from non-approved areas. Administrators can easily configure and manage these restrictions, effectively safeguarding sensitive data and resources.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/am-cont-3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/timer1.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Time-Based Access Restrictions
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth Access Manager allows administrators to define specific time windows for application access. This ensures that organizational applications are accessible only during designated periods. Outside these times, access is automatically restricted, providing enhanced security and precise control over access privileges.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Enhanced Security with Adaptive Multi-Factor Authentication (AMFA)
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth seamlessly integrates location- and time-based restrictions with its adaptive multi-factor authentication (AMFA) feature. In the event of a suspicious login attempt, AMFA is automatically triggered to verify the user's identity, providing an additional layer of protection and reinforcing access security.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/cont4.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/am-cont-5.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Comprehensive Insights with Smart Analytics
                        </h2>
                        <p className="text-gray-600 text-lg">

                            Smart Analytics for 1Auth Access Manager records detailed data on both successful and failed login attempts. Reports include user activity, accessed applications, timestamps, locations, and the authentication factors utilized. Additionally, insights into triggered AMFA checks highlight the most frequently used factors, enabling administrators to prioritize them for an optimized user experience.

                        </p>
                    </div>
                </div>
            </section>

        </>
    )

}



export default AccessManager;