import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const UserLifeCycle = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Effortless Automation from Onboarding to Exit
                        </h1>
                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">

                            Optimize time, reduce errors, and move closer to zero-trust security with streamlined access management. Automate every stage of the user lifecycle, from provisioning during onboarding, through role and access updates, to seamless deprovisioning at exit, ensuring efficiency and security at every step.


                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Unified Source of Truth for User Management
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth empowers you to designate a single platform as the definitive source for user management, whether it’s 1Auth Cloud Directory, Active Directory, or a third-party solution like your HRMS. Define user roles and permission rules directly within this platform for streamlined and consistent user management across your organization.


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/sec1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/sec2.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Automated Provisioning for
                            New Users

                        </h2>
                        <p className="text-gray-600 text-lg">
                            Automated User Provisioning Made Simple
                            Eliminate the need for manual permission assignments with 1Auth’s automated provisioning. Upon account creation, birthright provisioning grants users immediate access to essential applications required by all team members. Role-based provisioning further streamlines access, automatically assigning permissions based on predefined rules for each role, ensuring efficiency and accuracy in access management






                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Seamless Permission Control Throughout the User Lifecycle
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth simplifies access management during role changes or promotions with one-click role reassignment and automatic re-provisioning of permissions to match the updated position. For temporary suspensions, access revocation and restoration are completed in seconds, ensuring smooth transitions and maintaining security across all stages of the user lifecycle.


                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/sec3.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/timer.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            User-Initiated Access Update Requests
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth accommodates user requests for additional access beyond their predefined role-based permissions. To maintain robust security, all requests follow a structured approval workflow, requiring confirmation from the user’s manager before granting access, ensuring both flexibility and controlled access management.





                        </p>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Effortless and Secure One-Click Deprovisioning
                        </h2>
                        <p className="text-gray-600 text-lg">

                            1Auth simplifies off-boarding with one-click deprovisioning, ensuring that all access permissions are promptly revoked upon a user’s exit. For procedural necessities, rules can be configured to allow limited access, such as to payroll systems, for a defined period post-departure, ensuring both security and operational continuity.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/sec4.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/sec5.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Smart Analytics for Enhanced Access Insights
                        </h2>
                        <p className="text-gray-600 text-lg">


                            1Auth Access Manager’s Smart Analytics captures detailed data on successful and failed login attempts, including user activity, accessed applications, time, location, and authentication factors. Insights into triggered AMFA checks reveal the most frequently used factors, enabling administrators to optimize and prioritize them for a better user experience.


                        </p>
                    </div>
                </div>
            </section>

        </>

    )
}

export default UserLifeCycle