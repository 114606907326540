import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import b1Ill from '../../src/assets/illustration/b1.png';
import enterprise from '../../src/assets/illustration/7053246.jpg'
import education from '../../src/assets/illustration/21429.jpg'
import finance from '../../src/assets/illustration/515319-PIRK7R-406.jpg'
import healthcare from '../../src/assets/illustration/doc.jpg'
import ecommerce from '../../src/assets/illustration/ecom.jpg'
import travel from '../../src/assets/illustration/10966.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from '../components/caurosuel/Carousel';

// import './Header.scss';

const Home = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            once: true, // Whether animation should happen only once
        });
        AOS.refresh();

    }, []);
    return (
        <>
            <section className="main-banner2 h-[100vh] px-6 lg:px-24 sm:pt-[100px] md:pt-[110px]">
                <Carousel></Carousel>
            </section>

            <section className="w-full flex items-center justify-center text-center pt-10 pb-10" data-aos="zoom-in">
                <div className="w-[90vw] overflow-hidden">
                    <div className="text-center">
                        <h2 className="mb-10 text-5xl">
                            Effortless access to everything you need, all in one centralized hub.
                        </h2>
                        <p className="text-light mb-15 text-md text-gray-800">
                            <span className="text-gray-800 text-sm italic font-bold inline-block border border-gray-700 rounded px-1 py-0.5 mt-1">
                                1Auth
                            </span>{" "}
                            All-in-One Authentication app serves as a centralized hub for seamless
                            access management, offering{" "}
                            <span className="text-gray-800 text-sm font-bold border-gray-700 rounded px-1 py-0.5 mt-1">
                                ☝one-click logins
                            </span>{" "}
                            for users and streamlined controls for administrators, ensuring
                            efficiency and uncompromising security.
                        </p>
                    </div>

                    {/* <div className="mt-15 flex items-center justify-center mt-10">
                        <button className="relative px-8 py-3 text-white font-semibold rounded-lg overflow-hidden group focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                            <span className="absolute inset-0 bg-[#7829e3] animate-pulse transition-opacity duration-500 group-hover:opacity-0"></span>
                            <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 transform scale-x-0 origin-left transition-transform duration-500 ease-in-out group-hover:scale-x-100"></span>
                            <span className="relative z-10">Launch the app</span>
                        </button>
                    </div> */}
                </div>
            </section>


            <section className="bg-gray-50 py-12 px-6">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">Our Products</h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">

                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-lock h-8 w-8 mb-2 text-primary">
                                        <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">SSO/IDP</h3>
                            <p className="text-gray-600 mb-4">
                                Effortlessly access all your applications with All-in-One Authentication, seamlessly integrating 100+ apps through a Unified Directory Platform and advanced authentication.
                            </p>
                            <Link to="/ssoidp"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-fingerprint h-8 w-8 mb-2 text-primary">
                                        <path d="M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4"></path>
                                        <path d="M14 13.12c0 2.38 0 6.38-1 8.88"></path>
                                        <path d="M17.29 21.02c.12-.6.43-2.3.5-3.02"></path>
                                        <path d="M2 12a10 10 0 0 1 18-6"></path>
                                        <path d="M2 16h.01"></path>
                                        <path d="M21.8 16c.2-2 .131-5.354 0-6"></path>
                                        <path d="M5 19.5C5.5 18 6 15 6 12a6 6 0 0 1 .34-2"></path>
                                        <path d="M8.65 22c.21-.66.45-1.32.57-2"></path>
                                        <path d="M9 6.8a6 6 0 0 1 9 5.2v2"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Access Manager</h3>
                            <p className="text-gray-600 mb-4">
                                The Advanced Access Security System delivers robust protection with Credential-Free Authentication, surpassing traditional methods for seamless and modern security.
                            </p>
                            <Link to="/accessmanager"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-key-round h-8 w-8 mb-2 text-primary">
                                        <path
                                            d="M2.586 17.414A2 2 0 0 0 2 18.828V21a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h.172a2 2 0 0 0 1.414-.586l.814-.814a6.5 6.5 0 1 0-4-4z">
                                        </path>
                                        <circle cx="16.5" cy="7.5" r=".5" fill="currentColor"></circle>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Password Manager</h3>
                            <p className="text-gray-600 mb-4">
                                Effortlessly store and manage your app credentials with our advanced repository, ensuring seamless access and strong protection.
                            </p>
                            <Link to="/passwordmanager"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-user h-8 w-8 mb-2 text-primary">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Secure Access Framework</h3>
                            <p className="text-gray-600 mb-4">
                                Manage user access effectively with AASS controls, enforcing restrictions like network rules, trusted devices, location parameters, and time-bound access to ensure secure resource use.
                            </p>
                            <Link to="/accessmanager"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-cloud h-8 w-8 mb-2 text-primary">
                                        <path d="M18 19H6a4 4 0 0 1-1.33-7.76A5 5 0 0 1 16 6a5 5 0 0 1 4 8h-2"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Blockchain-Secured Cloud Hub</h3>
                            <p className="text-gray-600 mb-4">
                                Centralize user identities with a blockchain-secured platform, offering tamper-proof records and robust protection against hacking or spoofing, ensuring a trusted and secure solution.
                            </p>

                            <Link to="/clouddirectory"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <span className="inline-block text-gray-800 text-3xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" className="lucide lucide-refresh-cw h-8 w-8 mb-2 text-primary">
                                        <path d="M21 2v6h-6"></path>
                                        <path d="M3 12a9 9 0 0 1 15-6.7L21 8M3 22v-6h6"></path>
                                        <path d="M21 12a9 9 0 0 1-15 6.7L3 16"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Automated Access Lifecycle</h3>
                            <p className="text-gray-600 mb-4">
                                Effortlessly manage the entire user journey with end-to-end automation, from start to finish, ensuring seamless provisioning, de-provisioning, and access updates at every stage.
                            </p>
                            <Link to="/userlifecycle"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white py-12 px-6">

                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">Industries We Serve</h2>
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">

                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">

                                <img src={enterprise} alt="Enterprise"
                                    className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Corporate Access Platform</h3>
                            <p className="text-gray-600 mb-4">
                                Provide secure, scalable access tailored for global enterprises, including ITES, BPOs, multinational corporations, and other large-scale organizations.</p>
                            <Link to="/itesbpo"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">
                            <div className="mb-4">
                                <img src={education} alt="Education" className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Academic Access Platform
                            </h3>
                            <p className="text-gray-600 mb-4">
                                Simplify and secure access for students, faculty, and staff with an efficient and streamlined login management system.
                            </p>
                            <Link to="/higheredu"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-down" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <img src={finance} alt="Finance"
                                    className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Finance Access Management</h3>
                            <p className="text-gray-600 mb-4">
                                Ensure robust protection for sensitive financial data with advanced security and access management.
                            </p>
                            <Link to="/higheredu"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <img src={healthcare} alt="Healthcare" className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">
                                Medical Data Protection Platform
                            </h3>
                            <p className="text-gray-600 mb-4">
                                Ensure HIPAA-compliant access control and secure management of sensitive medical data for healthcare institutions.
                            </p>
                            <Link to="/healthcare"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4">
                                <img src={ecommerce} alt="Ecommerce" className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">

                                Online Retail Security Platform

                            </h3>
                            <p className="text-gray-600 mb-4">
                                Provide seamless and secure customer authentication tailored for online retailers, enhancing user experience and safeguarding data.
                            </p>
                            <Link to="/retail"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>


                    <div data-aos="fade-up" data-aos-duration="1000"
                        className="p-3 bg-white rounded-3xl shadow-sm hover:shadow-lg transition-transform transform hover:scale-105 duration-300">
                        <div className="border border-dashed border-gray-300 dark:border-borderColour-dark  rounded-3xl p-7">

                            <div className="mb-4"><img src={travel} alt="Travel"
                                className="mb-6 w-full rounded-md h-[250px]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Secure Travel Management Platform
                            </h3>
                            <p className="text-gray-600 mb-4">
                                Deliver robust identity management tailored for the travel industry, ensuring secure access and enhancing operational efficiency.
                            </p>
                            <Link to="/tourism"
                                className="px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-800 font-medium hover:bg-gray-100 transition-colors duration-200">
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;