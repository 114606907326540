import React from 'react';
import FreeTrailBtn from '../components/FreeTrailBtn';


const HealthCare = () => {
    return (
        <>
            <section className="main-banner2 h-[100vh] w-full flex items-center justify-center">
                <div className="container text-center">

                    <div className="text-center lg:text-left mt-14" data-aos="fade-right">
                        <h1 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Healthcare




                        </h1>
                        <h2 className="text-4xl lg:text-5xl font-bold text-gray-200">
                            Secure EHRs for Enhanced Patient Care and Compliance


                        </h2>

                        <p className="text-gray-400 mt-6 text-lg" data-aos="fade-right">
                            From major hospitals to clinics and diagnostic centers, managing large volumes of electronic health records (EHRs) and electronic medical records (EMRs) securely is a critical challenge. Effective Identity and Access Management (IAM) ensures data security, privacy, and regulatory compliance while providing streamlined access for healthcare professionals to maintain the quality of patient care.



                        </p>
                    </div>
                    <div data-aos="fade-up" className="text-center mt-12">
                        <FreeTrailBtn></FreeTrailBtn>
                    </div>
                </div>
            </section>

            <section className="max-w-7xl mx-auto px-6 py-16 text-center" data-aos="fade-right" data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Robust Security for Sensitive Medical Data
                </h1>
                <p className="text-gray-600 mb-8">
                    The healthcare industry must manage vast amounts of sensitive information, including patient profiles, appointments, medical histories, and treatment records. Protecting the privacy and security of these electronic health records (EHRs) is critical, as their misuse can have serious consequences.


                </p>
                <p className="font-semibold text-gray-800 mb-12">
                    Securing Medical Records: 4 Key Ways 1Auth Can Help
                </p>
                <div className="flex">
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon.png" alt="Shield Icon" className="h-12 w-12" style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            1Auth supports a zero-trust security strategy by carefully segregating roles and granting users only the minimum access required for their responsibilities, reducing risks and enhancing data protection.

                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon1.png" alt="Shield Icon" className="h-12 w-12" style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            1Auth eliminates the risk of fraudulent access by implementing multi-factor authentication, adding a robust verification layer beyond passwords to enhance security.


                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon2.png" alt="Shield Icon" className="h-12 w-12" style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            1Auth mitigates misuse and breaches by restricting user access to sensitive data and applications based on specific devices, networks, or designated shift times, ensuring tighter security controls.


                        </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-purple-100 p-4 rounded-full mb-4">
                            <img src="/banner-imgs/icon2.png" alt="Shield Icon" className="h-12 w-12" style={{ height: "24px" }} />
                        </div>
                        <p className="text-gray-600">
                            Plugging gaps in security by revoking access for users who no longer need it, or for employees
                            exiting your organization
                        </p>
                    </div>
                </div>
                <p className="font-semibold text-gray-800 mt-12 mb-8">
                    1Auth strengthens security by promptly revoking access for users who no longer require it or for employees exiting the organization, preventing unauthorized access.


                </p>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Streamlined Access for Better Patient Care
                        </h2>
                        <p className="text-gray-600 text-lg">
                            From doctors and nurses to lab technicians and administrators, healthcare facilities rely on data to function efficiently. When the right person cannot access the right data at the right time, the consequences can be critical. Identity and access management (IAM) helps balance robust security with convenient, streamlined access for authorized users.


                            <br /><br />
                            With 1Auth’s single sign-on, team members log in once to access applications spanning hospital management, recordkeeping, PACS, image management, diagnostics, and billing. This seamless access frees up valuable time for better patient care. Additionally, 1Auth’s self-service password management empowers users to set and recover passwords or configure preferred multi-factor or passwordless authentication methods, saving time and effort for both users and administrators.


                            <br /><br />
                            Boost efficiency, save time, and maintain strong security with 1Auth’s SSO & IdP and Password Manager.


                            <br /><br />
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/heal1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>


            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 flex items-center justify-center mb-12 lg:mb-0" data-aos="fade-right"
                        data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className="relative">
                            <img src="/banner-imgs/doc.png" alt="Configuration Icon" className="w-full max-w-md" />
                        </div>
                    </div>


                    <div className="lg:w-1/2" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Improved Compliance with HIPAA</h2>
                        <p className="text-gray-600 text-lg">
                            1Auth supports HIPAA compliance through:

                            Access Control: Restricting and managing user access to Protected Health Information (PHI) based on roles and responsibilities.<br /><br />
                            Data Protection: Implementing robust measures to safeguard PHI against data breaches. <br /><br />
                            Unauthorized Access Detection: Monitoring and identifying unauthorized attempts to access PHI.  <br /><br />
                            Compliance Demonstration: Providing audit logs and documenting processes to demonstrate compliance efforts.
                            Ensure secure and compliant healthcare operations with 1Auth’s IAM solutions.



                        </p>
                    </div>
                </div>
            </section>



            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col lg:flex-row items-center justify-between">

                    <div className="lg:w-1/2 mb-12 lg:mb-0" data-aos="fade-right" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            How 1Auth Tackles Key HIPAA Compliance Challenges
                        </h2>
                        <p className="text-gray-600 text-lg">
                            1Auth effectively addresses HIPAA compliance challenges through its robust suite of solutions:

                            <strong>Granular Access Control:</strong> Enables detailed, role-based access control with clear visibility into which users or roles have access to specific resources. <br></br>
                            <strong>Enhanced Security Measures:</strong> Protects against breaches with multi-factor authentication and access restrictions based on IP, device, time, and location.<br></br>
                            <strong>Comprehensive Audit Logs: </strong>Tracks all user activity, simplifying and expediting regulatory audits.<br></br>
                            <strong>Smart Analytics Dashboard:</strong> Provides full transparency and visibility across all users and applications through an intuitive interface.<br></br>
                            Strengthen HIPAA compliance efforts with 1Auth’s advanced IAM products.
                        </p>
                    </div>


                    <div className="lg:w-1/2 flex items-center justify-center" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <img src="/banner-imgs/doc1.png" alt="App Connectors" className="w-full max-w-lg" />
                    </div>
                </div>
            </section>

        </>

    )


}



export default HealthCare;